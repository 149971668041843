/* table filters
   ========================================================================== */

.table-filters {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-fixed + 2;
  color: $table-filters-color;
  background-color: $table-filters-bg;

  &:not(.active) {
    display: none;
  }
}

.table-filters-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(65px);
  padding-right: rem(10px);
  padding-left: rem(30px);
  border-bottom: 1px solid $border-color;
}

.table-filters-title {
  font-weight: $font-weight-medium;
  color: $table-filters-title-color;
}

.table-filters-menu {
  padding: rem(24px) rem(33px) rem(24px) rem(30px);
  overflow-y: auto;
}

.filters-menu-item {
  width: 100%;
  padding: 0;
  font-weight: $font-weight-medium;
  color: $table-filters-item-color;
  text-align: left;
  cursor: pointer;
  background: none;
  border: 0;

  &.has-hover {
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: theme-color("primary");
    }
  }

  + .filters-menu-item {
    margin-top: rem(24px);
  }
}

.filters-group {
  + .filters-group {
    margin-top: rem(30px);
  }
}

.filters-group-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  [class^="icons-arrow-"] {
    display: inline-block;
    transition: transform .15s ease-out;
  }

  &[aria-expanded="true"] {
    [class^="icons-arrow-"] {
      transform: rotate(180deg);
    }
  }
}

.filters-group-title {
  padding: 0;
  font-weight: $font-weight-medium;
  color: $table-filters-group-title-color;
  text-align: left;
  cursor: pointer;
  background: none;
  border: 0;
}

.filters-group-content {
  .filters-menu-item:first-child {
    padding-top: rem(20px);
  }
}

@include media-breakpoint-down(sm) {
  .table-filters {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  .table-filters-head {
    flex: 0 0 1;
  }
  .table-filters-menu {
    flex: 1 1 100%;
  }
}

@include media-breakpoint-up(md) {
  .table-filters {
    top: ($mastheader-height-md + $actionbar-min-height-md);
    width: rem(333px);
    border: 1px solid $border-color;
    border-right: none;
  }

  .table-filters-menu {
    max-height: rem(425px);
    padding-right: rem(35px);
  }

  .table-filters-head {
    padding-right: rem(13px);
  }

  .table-filters-title {
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(lg) {
  .table-filters {
    top: ($mastheader-height-lg + $actionbar-min-height-lg);
  }
}

@include media-breakpoint-up(xl) {
  .table-filters {
    top: ($mastheader-height-xl + $actionbar-min-height-xl);
  }

  .table-filters-menu {
    padding-right: rem(52px);
  }

  .table-filters-head {
    padding-right: rem(30px);
  }
}
