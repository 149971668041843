/* chart tooltip
   ========================================================================== */

/* stylelint-disable selector-max-id */
#chartjs-tooltip {
  padding: rem(18px);
  pointer-events: none;
  background-color: $charts-tooltip-bg;
  border-radius: rem(3px);

  th {
    font-weight: $font-weight-base;
  }
}

.chartjs-tooltip-title {
  padding-bottom: rem(2px);
  color: $charts-tooltip-title-color;
}

.chartjs-tooltip-line-indicator {
  position: relative;
  display: inline-block;
  width: rem(20px);
  height: rem(3px);
  margin-right: rem(8px);
  margin-bottom: rem(2px);
}
/* stylelint-enable selector-max-id */
