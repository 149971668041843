/* charts
   ========================================================================== */

.chart-head {
  display: flex;
  flex-direction: column;
}

.chart-caption {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.chart-caption-item {
  display: flex;
  align-items: center;

  &::before {
    display: block;
    width: rem(19px);
    height: rem(19px);
    margin-right: rem(10px);
    content: "";
    background-color: theme-color("primary");
  }

  &.line::before,
  &.dashed::before {
    width: rem(25px);
    height: rem(3px);
  }
  &.dashed::before {
    background: linear-gradient(to right, theme-color("primary") 50%, transparent 50%);
    background-size: 4px;
  }

  &:not(:last-child) {
    padding-right: rem(20px);
  }
}

@include media-breakpoint-up(md) {
  .chart-head {
    flex-direction: row;
    justify-content: space-between;
    padding: rem(12px) rem(30px);
    background-color: $charts-head-bg;
  }
}
