/* schedule filters
   ========================================================================== */

.schedule-filters {
  background-color: color("white");
}

.schedule-filters-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(65px);
  padding-right: rem(10px);
  padding-left: rem(30px);
  border-bottom: 1px solid $border-color;
}

.schedule-filters-title {
  font-weight: $font-weight-medium;
  color: gray("600");
}

.schedule-filters-menu {
  padding: rem(24px) rem(33px) rem(24px) rem(30px);
  overflow-y: auto;
}

@include media-breakpoint-down(md) {
  .schedule-filters {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $zindex-fixed + 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    &:not(.active) {
      display: none;
    }
  }

  .schedule-filters-head {
    flex: 0 0 1;
  }
  .schedule-filters-menu {
    flex: 1 1 100%;
    width: 100%;
    max-width: rem(530px);
    margin-right: auto;
    margin-left: auto;
  }
}

@include media-breakpoint-up(lg) {
  .schedule-filters-head {
    display: none;
  }
}
