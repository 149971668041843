/* mobile-menu
   ========================================================================== */

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-tooltip + 2;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, .6);
}

.mobile-menu-inner {
  position: relative;
  flex: 0 1 auto;
  width: 100%;
  overflow-y: auto;
  background-color: $mobile-menu-bg;
}

.mobile-menu-content {
  overflow-y: scroll;
}

.mobile-menu-close {
  position: absolute;
  top: rem(20px);
  right: rem(20px);
  color: $mobile-menu-close-color;
  background: transparent;
  border: none;

  &:hover,
  &:focus {
    color: color("primary");
  }
}

@include media-breakpoint-up(lg) {
  .mobile-menu {
    display: none;
  }
}
