// stylelint-disable declaration-no-important

//
// Right side table of contents
//

.bd-toc {
  @supports (position: sticky) {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee;

  ul {
    padding-left: 1rem;

    ul {
      display: none;
    }
  }
}

.bd-sidebar {
  order: 0;
  color: #fff;
  background-color: $blue;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.bd-search {
  position: relative; // To contain the Algolia search
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.bd-search .form-control-container {
  display: block;
  width: 100%;
}

.bd-search-docs-toggle {
  line-height: 1;
  color: #fff;
}

:not(.active) > .bd-sidenav {
  display: none;
}

.bd-sidenav-group-link {
  padding: .25rem .625rem .25rem 1rem;
  font-weight: 500;
  color: $doc-sidebar-color;
  @include border-radius(.25rem);

  > * { pointer-events: none; }

  &:hover,
  &:focus {
    color: #004468;
    text-decoration: none;
  }
}

.bd-sidenav-group {
  &.has-children .bd-sidenav-group-link::after {
    display: inline-block;
    width: .875rem;
    height: .875rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
    margin-left: .5rem;
    color: $doc-sidebar-color;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='#{$doc-sidebar-stroke}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    transform: rotate(90deg);
  }

  &.active {
    .bd-sidenav-group-link::after {
      transform: rotate(-90deg);
    }

    > .bd-sidenav-group-link {
      color: $doc-sidebar-color;
    }
    margin-bottom: 1rem;
  }

  &.active:not(:first-child) {
    margin-top: 1rem;
  }
}

// All levels of nav
.bd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem .25rem 2rem;
  font-size: 90%;
  color: $doc-sidebar-color;
}

.bd-sidebar .nav > li > a:hover {
  color: #004468;
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: #004468;
  background-color: transparent;
}
