/* planning menu
   ========================================================================== */

.schedule-sidebar-planning {
  overflow: hidden;
}

.schedule-planning-menu {
  height: 100%;
  padding-top: rem(60px);
  background-color: gray("100");
}

.schedule-planning-menu-inner {
  height: 100%;
  padding-top: rem(20px);
  padding-right: rem(30px);
  padding-bottom: rem(20px);
  padding-left: rem(30px);
  overflow-y: auto;
  background-color: color("white");

  .event-planning + .event-planning {
    margin-top: rem(10px);
  }
}
