/* options-menu
   ========================================================================== */

.options-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-navigation + 2;
  background-color: color("white");

  &:not(.active) {
    display: none;
  }
}

.options-menu-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(65px);
  padding-right: rem(10px);
  padding-left: rem(30px);
  border-bottom: 1px solid $border-color;
}

.options-menu-title {
  font-weight: $font-weight-medium;
  color: gray("600");
}

@include media-breakpoint-down(md) {
  .options-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - #{$mastnav-height-sm} - 1px);
  }

  .options-menu-head {
    flex: 0 0 1;
  }
  .options-menu-menu {
    flex: 1 1 100%;
  }
}

