.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #333;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #b9b9b9; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #d7d7d7 #d7d7d7 #b9b9b9; }
    .nav-tabs .nav-link.disabled {
      color: #333;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #000;
    border-color: #b9b9b9 #b9b9b9 #000; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.4375rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: #00a1ff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.32812rem;
  padding-bottom: 0.32812rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.4375rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1279.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1280px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

/*!
 * Bootstrap Docs (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */
.bd-navbar {
  min-height: 4rem;
  background-color: #333;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 1023.98px) {
    .bd-navbar {
      padding-right: .5rem;
      padding-left: .5rem; }
      .bd-navbar .navbar-nav-scroll {
        max-width: 100%;
        height: 2.5rem;
        margin-top: .25rem;
        overflow: hidden;
        font-size: .875rem; }
        .bd-navbar .navbar-nav-scroll .navbar-nav {
          padding-bottom: 2rem;
          overflow-x: auto;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch; } }
  @media (min-width: 768px) {
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .bd-navbar {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1071; } } }
  .bd-navbar .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
    color: #fff; }
    .bd-navbar .navbar-nav .nav-link.active, .bd-navbar .navbar-nav .nav-link:hover {
      color: rgba(255, 255, 255, 0.75);
      background-color: transparent; }
    .bd-navbar .navbar-nav .nav-link.active {
      font-weight: 500; }
  .bd-navbar .nav-item {
    width: 100%; }
    .bd-navbar .nav-item + .nav-item {
      padding-left: 0; }
  @media (max-width: 767.98px) {
    .bd-navbar .nav-item {
      width: 100%; }
      .bd-navbar .nav-item + .nav-item {
        margin-top: 0; } }
  .bd-navbar .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top; }
  .bd-navbar .dropdown-menu {
    font-size: .875rem; }
  .bd-navbar .dropdown-item.active {
    font-weight: 500;
    color: #212529;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem; }

.dropdown-doc-navbar .dropdown-menu {
  top: 150%;
  padding: 1.875rem;
  background-color: #333;
  border-color: #333; }
  .dropdown-doc-navbar .dropdown-menu::before, .dropdown-doc-navbar .dropdown-menu::after {
    top: -1.1rem;
    right: 1.4rem;
    border-bottom-color: #333; }

.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="bottom-start"]::after,
.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="bottom-end"]::after,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="bottom-start"]::after,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="bottom-end"]::after {
  border-bottom-color: #333; }

.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="bottom-start"]::before,
.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="bottom-end"]::before,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="bottom-start"]::before,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="bottom-end"]::before {
  border-bottom-color: #333; }

.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="top-start"]::after,
.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="top-end"]::after,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="top-start"]::after,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="top-end"]::after {
  border-top-color: #333; }

.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="top-start"]::before,
.dropdown-doc-navbar.dropdown .dropdown-menu[x-placement="top-end"]::before,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="top-start"]::before,
.dropdown-doc-navbar.dropup .dropdown-menu[x-placement="top-end"]::before {
  border-top-color: #333; }

.dropdown-doc-navbar .dropdown-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  color: #fff;
  cursor: pointer; }

.bd-masthead {
  position: relative;
  padding: 3rem 10px;
  background-color: #000; }
  .bd-masthead h1 {
    line-height: 1; }
  .bd-masthead .btn:not(.btn-link) {
    width: 100%;
    padding: .8rem 2rem;
    font-size: 1.25rem;
    font-weight: 500; }
  .bd-masthead .carbonad {
    margin-top: 0 !important;
    margin-bottom: -3rem !important; }
  @media (min-width: 576px) {
    .bd-masthead {
      padding-top: 5rem;
      padding-bottom: 5rem; }
      .bd-masthead .carbonad {
        margin-bottom: 0 !important; } }
  @media (min-width: 768px) {
    .bd-masthead h1 {
      font-size: 4rem; }
    .bd-masthead .carbonad {
      margin-top: 3rem !important; } }

.half-rule {
  width: 6rem;
  margin: 2.5rem 0; }

.masthead-followup .bd-clipboard {
  display: none; }

.masthead-followup .highlight {
  padding: .5rem 0;
  background-color: transparent; }

#carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.05); }
  #carbonads a {
    color: #333;
    text-decoration: none; }
  @media (min-width: 576px) {
    #carbonads {
      max-width: 330px;
      border-radius: 4px; } }

.carbon-img {
  float: left;
  margin-left: -145px; }

.carbon-poweredby {
  display: block;
  color: #777 !important; }

.bd-content {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  background-color: #000; }
  .bd-content > h2[id],
  .bd-content > h3[id],
  .bd-content > h4[id] {
    pointer-events: none; }
    .bd-content > h2[id] > div,
    .bd-content > h2[id] > a,
    .bd-content > h3[id] > div,
    .bd-content > h3[id] > a,
    .bd-content > h4[id] > div,
    .bd-content > h4[id] > a {
      pointer-events: auto; }
    .bd-content > h2[id]::before,
    .bd-content > h3[id]::before,
    .bd-content > h4[id]::before {
      display: block;
      height: 6rem;
      margin-top: -6rem;
      visibility: hidden;
      content: ""; }
  .bd-content > table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem; }
    @media (max-width: 1023.98px) {
      .bd-content > table {
        display: block;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar; }
        .bd-content > table.table-bordered {
          border: 0; } }
    .bd-content > table > thead > tr > th,
    .bd-content > table > thead > tr > td,
    .bd-content > table > tbody > tr > th,
    .bd-content > table > tbody > tr > td,
    .bd-content > table > tfoot > tr > th,
    .bd-content > table > tfoot > tr > td {
      padding: 0 1.25rem;
      vertical-align: top;
      border: 1px solid #4d4f53; }
      .bd-content > table > thead > tr > th > p:last-child,
      .bd-content > table > thead > tr > td > p:last-child,
      .bd-content > table > tbody > tr > th > p:last-child,
      .bd-content > table > tbody > tr > td > p:last-child,
      .bd-content > table > tfoot > tr > th > p:last-child,
      .bd-content > table > tfoot > tr > td > p:last-child {
        margin-bottom: 0; }
    .bd-content > table td:first-child > code {
      white-space: nowrap; }

.bd-content > h2:not(:first-child) {
  margin-top: 3rem;
  font-size: 2rem; }

.bd-content > h3 {
  margin-top: 1.5rem; }

.bd-content > ul li,
.bd-content > ol li {
  margin-bottom: .25rem; }

@media (min-width: 1024px) {
  .bd-content > ul,
  .bd-content > ol,
  .bd-content > p {
    max-width: 80%; } }

.bd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: 300; }
  @media (min-width: 576px) {
    .bd-title {
      font-size: 3rem; } }

.bd-lead {
  font-size: 1.125rem;
  font-weight: 300; }
  @media (min-width: 576px) {
    .bd-lead {
      max-width: 80%;
      margin-bottom: 1rem;
      font-size: 1.5rem; } }

.bd-text-purple {
  color: #6e1e78; }

.bd-text-purple-bright {
  color: #ab28bb; }

#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #6e1e78; }
  #skippy .skiplink-text {
    padding: .5em;
    outline: 1px dotted; }

.bd-footer {
  font-size: 85%;
  color: #f2f2f2;
  text-align: center;
  background-color: #333; }
  .bd-footer a {
    font-weight: 500;
    color: #f2f2f2; }
    .bd-footer a:hover, .bd-footer a:focus {
      color: #00a1ff; }
  .bd-footer p {
    margin-bottom: 0; }
  @media (min-width: 576px) {
    .bd-footer {
      text-align: left; } }

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem; }
  .bd-footer-links li {
    display: inline-block; }
    .bd-footer-links li + li {
      margin-left: 1rem; }

.bd-toc {
  border-left: 1px solid #747678; }

.bd-toc nav ul {
  padding-left: 1rem;
  list-style: none; }
  .bd-toc nav ul:first-child {
    padding: 0; }
  .bd-toc nav ul a {
    display: block;
    padding: .125rem 1.5rem;
    color: #f2f2f2; }
    .bd-toc nav ul a:hover {
      color: #00a1ff;
      text-decoration: none; }

.ig-container {
  font-size: 2rem;
  color: #00a1ff; }
  .ig-container > .col {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; }

.ig-color {
  cursor: pointer; }

.ig-button {
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  border: 2px solid #fff; }
  .ig-button:focus {
    color: inherit;
    background-color: #fff;
    border-color: #fff;
    outline: 0; }
  .ig-button:hover {
    color: inherit;
    background-color: #fff;
    border-color: currentColor; }
  .ig-button.selected {
    border-color: currentColor;
    border-width: 4px; }

.ig-button,
.ig-icon {
  font-size: inherit;
  color: inherit; }

.bd-toc {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-toc {
      position: -webkit-sticky;
      position: sticky;
      top: 4rem;
      height: calc(100vh - 4rem);
      overflow-y: auto; } }

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee; }
  .section-nav ul {
    padding-left: 1rem; }
    .section-nav ul ul {
      display: none; }

.bd-sidebar {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
      -ms-flex-order: 0;
          order: 0;
  color: #fff;
  background-color: #00a1ff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  @media (min-width: 768px) {
    .bd-sidebar {
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
      @supports ((position: -webkit-sticky) or (position: sticky)) {
        .bd-sidebar {
          position: -webkit-sticky;
          position: sticky;
          top: 4rem;
          z-index: 1000;
          height: calc(100vh - 4rem); } } }
  @media (min-width: 1280px) {
    .bd-sidebar {
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 320px;
          -ms-flex: 0 1 320px;
              flex: 0 1 320px; } }

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px; }
  @media (min-width: 768px) {
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .bd-links {
        max-height: calc(100vh - 9rem);
        overflow-y: auto; } } }
  @media (min-width: 768px) {
    .bd-links {
      display: block !important; } }

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.bd-search .form-control-container {
  display: block;
  width: 100%; }

.bd-search-docs-toggle {
  line-height: 1;
  color: #fff; }

:not(.active) > .bd-sidenav {
  display: none; }

.bd-sidenav-group-link {
  padding: .25rem .625rem .25rem 1rem;
  font-weight: 500;
  color: #000;
  border-radius: 0.25rem; }
  .bd-sidenav-group-link > * {
    pointer-events: none; }
  .bd-sidenav-group-link:hover, .bd-sidenav-group-link:focus {
    color: #004468;
    text-decoration: none; }

.bd-sidenav-group.has-children .bd-sidenav-group-link::after {
  display: inline-block;
  width: .875rem;
  height: .875rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-left: .5rem;
  color: #000;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }

.bd-sidenav-group.active {
  margin-bottom: 1rem; }
  .bd-sidenav-group.active .bd-sidenav-group-link::after {
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .bd-sidenav-group.active > .bd-sidenav-group-link {
    color: #000; }

.bd-sidenav-group.active:not(:first-child) {
  margin-top: 1rem; }

.bd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem .25rem 2rem;
  font-size: 90%;
  color: #000; }

.bd-sidebar .nav > li > a:hover {
  color: #004468;
  text-decoration: none;
  background-color: transparent; }

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: #004468;
  background-color: transparent; }

.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2); }

.bd-example-row .row + .row {
  margin-top: 1rem; }

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1); }

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1); }

.bd-highlight {
  background-color: rgba(110, 30, 120, 0.15);
  border: 1px solid rgba(110, 30, 120, 0.15); }

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.example-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.example-content-main {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }
  @media (min-width: 576px) {
    .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1024px) {
    .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
          -ms-flex: 0 0 66.66667%;
              flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.example-content-secondary {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }
  @media (min-width: 576px) {
    .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1024px) {
    .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.bd-example-container {
  min-width: 16rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto; }

.bd-example-container-header {
  height: 3rem;
  margin-bottom: .5rem;
  background-color: white;
  border-radius: .25rem; }

.bd-example-container-sidebar {
  float: right;
  width: 4rem;
  height: 8rem;
  background-color: #80d0ff;
  border-radius: .25rem; }

.bd-example-container-body {
  height: 8rem;
  margin-right: 4.5rem;
  background-color: #c145d1;
  border-radius: .25rem; }

.bd-example-container-fluid {
  max-width: none; }

.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -10px 0;
  border: solid #333;
  border-width: .2rem 0 0; }
  .bd-example::after {
    display: block;
    clear: both;
    content: ""; }
  @media (min-width: 576px) {
    .bd-example {
      padding: 1.5rem;
      margin-right: 0;
      margin-left: 0;
      border-width: .2rem; } }
  .bd-example + .highlight,
  .bd-example + .clipboard + .highlight {
    margin-top: 0; }
  .bd-example + p {
    margin-top: 2rem; }
  .bd-example .pos-f-t {
    position: relative;
    margin: -1rem; }
    @media (min-width: 576px) {
      .bd-example .pos-f-t {
        margin: -1.5rem; } }
  .bd-example .custom-file-input:lang(es) ~ .custom-file-label::after {
    content: "Elegir"; }
  .bd-example > .form-control + .form-control {
    margin-top: .5rem; }
  .bd-example > .nav + .nav,
  .bd-example > .alert + .alert,
  .bd-example > .navbar + .navbar,
  .bd-example > .progress + .progress,
  .bd-example > .progress + .btn {
    margin-top: 1rem; }
  .bd-example > .dropdown-menu:first-child {
    position: static;
    display: block; }
  .bd-example > .form-group:last-child {
    margin-bottom: 0; }
  .bd-example > .close {
    float: none; }

.bd-example-type .table .type-info {
  color: #999;
  vertical-align: middle; }

.bd-example-type .table td {
  padding: 1rem 0;
  border-color: #eee; }

.bd-example-type .table tr:first-child td {
  border-top: 0; }

.bd-example-type h1,
.bd-example-type h2,
.bd-example-type h3,
.bd-example-type h4,
.bd-example-type h5,
.bd-example-type h6 {
  margin-top: 0;
  margin-bottom: 0; }

.bd-example-bg-classes p {
  padding: 1rem; }

.bd-example > img + img {
  margin-left: .5rem; }

.bd-example > .btn-group {
  margin-top: .25rem;
  margin-bottom: .25rem; }

.bd-example > .btn-toolbar + .btn-toolbar {
  margin-top: .5rem; }

.bd-example-control-sizing select,
.bd-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: .5rem; }

.bd-example-form .input-group {
  margin-bottom: .5rem; }

.bd-example > textarea.form-control {
  resize: vertical; }

.bd-example .fixed-top,
.bd-example .sticky-top {
  position: static;
  margin: -1rem -1rem 1rem; }

.bd-example .fixed-bottom {
  position: static;
  margin: 1rem -1rem -1rem; }

@media (min-width: 576px) {
  .bd-example .fixed-top,
  .bd-example .sticky-top {
    margin: -1.5rem -1.5rem 1rem; }
  .bd-example .fixed-bottom {
    margin: 1rem -1.5rem -1.5rem; } }

.bd-example .pagination {
  margin-top: .5rem;
  margin-bottom: .5rem; }

.bd-docs .modal {
  z-index: 1072; }
  .bd-docs .modal .tooltip,
  .bd-docs .modal .popover {
    z-index: 1073; }

.bd-docs .modal-backdrop {
  z-index: 1071; }

.bd-example-modal {
  background-color: #000; }
  .bd-example-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block; }
  .bd-example-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto; }

.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem; }

.bd-example-popover-static {
  padding-bottom: 1.5rem;
  background-color: #f9f9f9; }
  .bd-example-popover-static .popover {
    position: relative;
    display: block;
    float: left;
    width: 260px;
    margin: 1.25rem; }

.tooltip-demo a {
  white-space: nowrap; }

.bd-example-tooltip-static .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1; }

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto; }

.scrollspy-example-2 {
  position: relative;
  height: 350px;
  overflow: auto; }

.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: .25rem;
  background-color: #f5f5f5; }

.bd-example-border-utils-0 [class^="border"] {
  border: 1px solid #4d4f53; }

.highlight {
  padding: 0 1rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #333;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  @media (min-width: 576px) {
    .highlight {
      padding: 0 1.5rem 1.5rem; } }

.bd-content .highlight {
  margin-right: -10px;
  margin-left: -10px; }
  @media (min-width: 576px) {
    .bd-content .highlight {
      margin-right: 0;
      margin-left: 0; } }

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0; }

.highlight pre code {
  font-size: inherit;
  color: #f2f2f2; }

.bd-example .actionbar,
.bd-example .mastheader {
  position: relative;
  top: 0;
  right: 0;
  width: 100%; }

.bd-example .mastnav {
  position: relative;
  top: 0;
  right: 0; }

.bd-example .actionbar,
.bd-example .card {
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1); }

.btn-bd-primary {
  font-weight: 500;
  color: #ab28bb;
  border-color: #ab28bb; }
  .btn-bd-primary:hover, .btn-bd-primary:active {
    color: #fff;
    background-color: #ab28bb;
    border-color: #ab28bb; }

.btn-bd-download {
  font-weight: 500;
  color: #ffe484;
  border-color: #ffe484; }
  .btn-bd-download:hover, .btn-bd-download:active {
    color: #333;
    background-color: #ffe484;
    border-color: #ffe484; }

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: #333;
  border: 1px solid #333;
  border-left-width: .25rem;
  border-radius: .25rem; }

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem; }

.bd-callout p:last-child {
  margin-bottom: 0; }

.bd-callout code {
  border-radius: .25rem; }

.bd-callout + .bd-callout {
  margin-top: -.25rem; }

.bd-callout-info {
  border-left-color: #00abb9; }
  .bd-callout-info h4 {
    color: #00abb9; }

.bd-callout-warning {
  border-left-color: #ffb612; }
  .bd-callout-warning h4 {
    color: #ffb612; }

.bd-callout-danger {
  border-left-color: #ff6e20; }
  .bd-callout-danger h4 {
    color: #ff6e20; }

.bd-examples .img-thumbnail {
  margin-bottom: .75rem; }

.bd-examples h4 {
  margin-bottom: .25rem; }

.bd-examples p {
  margin-bottom: 1.25rem; }

@media (max-width: 480px) {
  .bd-examples {
    margin-right: -.75rem;
    margin-left: -.75rem; }
  .bd-examples > [class^="col-"] {
    padding-right: .75rem;
    padding-left: .75rem; } }

.doc-example-link {
  -webkit-transition: .3s;
  transition: .3s; }

.doc-example-link:hover {
  background-color: #333;
  opacity: .6; }

.bd-browser-bugs td p {
  margin-bottom: 0; }

.bd-browser-bugs th:first-child {
  width: 18%; }

.bd-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  color: #563d7c;
  background-color: #f9f9f9;
  border-radius: .25rem; }

.bd-brand-item {
  padding: 4rem 0;
  text-align: center; }

.bd-brand-item + .bd-brand-item {
  border-top: 1px solid #fff; }

.bd-brand-logos .inverse {
  color: #fff;
  background-color: #563d7c; }

.bd-brand-item h1,
.bd-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0; }

.bd-brand-item .bd-booticon {
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .bd-brand-item {
    display: table-cell;
    width: 1%; }
  .bd-brand-item + .bd-brand-item {
    border-top: 0;
    border-left: 1px solid #fff; }
  .bd-brand-item h1 {
    font-size: 4rem; } }

.color-swatches {
  margin: 0 -5px;
  overflow: hidden; }

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: .25rem; }
  @media (min-width: 768px) {
    .color-swatch {
      width: 6rem;
      height: 6rem; } }

.color-swatches .bd-purple {
  background-color: #6e1e78; }

.color-swatches .bd-purple-light {
  background-color: #df93e8; }

.color-swatches .bd-purple-lighter {
  background-color: #e5e1ea; }

.color-swatches .bd-gray {
  background-color: #f9f9f9; }

.swatch-blue {
  color: #fff;
  background-color: #00a1ff; }

.swatch-purple {
  color: #fff;
  background-color: #6e1e78; }

.swatch-pink {
  color: #fff;
  background-color: #a1006b; }

.swatch-red {
  color: #fff;
  background-color: #cd0037; }

.swatch-orange {
  color: #fff;
  background-color: #ff6e20; }

.swatch-yellow {
  color: #212529;
  background-color: #ffb612; }

.swatch-green {
  color: #212529;
  background-color: #82be00; }

.swatch-teal {
  color: #212529;
  background-color: #d2e100; }

.swatch-cyan {
  color: #fff;
  background-color: #00abb9; }

.swatch-white {
  color: #212529;
  background-color: #fff; }

.swatch-black {
  color: #fff;
  background-color: #000; }

.swatch-gray {
  color: #fff;
  background-color: #333; }

.swatch-gray-dark {
  color: #fff;
  background-color: #343a40; }

.swatch-primary {
  color: #fff;
  background-color: #00a1ff; }

.swatch-primary-dark {
  color: #fff;
  background-color: #0074af; }

.swatch-secondary {
  color: #212529;
  background-color: #f2f2f2; }

.swatch-success {
  color: #212529;
  background-color: #82be00; }

.swatch-info {
  color: #fff;
  background-color: #00abb9; }

.swatch-warning {
  color: #212529;
  background-color: #ffb612; }

.swatch-danger {
  color: #fff;
  background-color: #ff6e20; }

.swatch-light {
  color: #212529;
  background-color: #f2f2f2; }

.swatch-dark {
  color: #fff;
  background-color: #343a40; }

.swatch-white {
  color: #212529;
  background-color: #fff; }

.swatch-100 {
  color: #212529;
  background-color: #f2f2f2; }

.swatch-200 {
  color: #212529;
  background-color: #d7d7d7; }

.swatch-300 {
  color: #212529;
  background-color: #b9b9b9; }

.swatch-400 {
  color: #fff;
  background-color: #747678; }

.swatch-500 {
  color: #fff;
  background-color: #4d4f53; }

.swatch-600 {
  color: #fff;
  background-color: #333; }

.swatch-700 {
  color: #fff;
  background-color: #495057; }

.swatch-800 {
  color: #fff;
  background-color: #343a40; }

.swatch-900 {
  color: #fff;
  background-color: #212529; }

.bd-clipboard {
  position: relative;
  display: none;
  padding: .5rem;
  text-align: right; }
  .bd-clipboard + .highlight {
    margin-top: 0; }

.bd-clipboard-bg-light {
  background-color: #333; }

.btn-hide-source,
.btn-show-source {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: .25rem 1rem;
  font-size: 75%;
  color: #f2f2f2;
  cursor: pointer;
  background-color: transparent;
  border: 0; }
  .btn-hide-source:hover,
  .btn-show-source:hover {
    opacity: .7; }

.btn-clipboard {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: .25rem 1rem;
  font-size: 75%;
  cursor: pointer;
  border: 0;
  border-radius: .25rem; }

@media (min-width: 768px) {
  .bd-clipboard {
    display: block; } }

.hll {
  background-color: #ffc; }

.c {
  color: #999; }

.k {
  color: #069; }

.o {
  color: #555; }

.cm {
  color: #999; }

.cp {
  color: #099; }

.c1 {
  color: #999; }

.cs {
  color: #999; }

.gd {
  background-color: #fcc;
  border: 1px solid #c00; }

.ge {
  font-style: italic; }

.gr {
  color: #f00; }

.gh {
  color: #030; }

.gi {
  background-color: #cfc;
  border: 1px solid #0c0; }

.go {
  color: #aaa; }

.gp {
  color: #009; }

.gu {
  color: #030; }

.gt {
  color: #9c6; }

.kc {
  color: #069; }

.kd {
  color: #069; }

.kn {
  color: #069; }

.kp {
  color: #069; }

.kr {
  color: #069; }

.kt {
  color: #078; }

.m {
  color: #f60; }

.s {
  color: #d44950; }

.na {
  color: #4f9fcf; }

.nb {
  color: #366; }

.nc {
  color: #0a8; }

.no {
  color: #360; }

.nd {
  color: #99f; }

.ni {
  color: #999; }

.ne {
  color: #c00; }

.nf {
  color: #c0f; }

.nl {
  color: #99f; }

.nn {
  color: #0cf; }

.nt {
  color: #2f6f9f; }

.nv {
  color: #033; }

.ow {
  color: #000; }

.w {
  color: #bbb; }

.mf {
  color: #f60; }

.mh {
  color: #f60; }

.mi {
  color: #f60; }

.mo {
  color: #f60; }

.sb {
  color: #c30; }

.sc {
  color: #c30; }

.sd {
  font-style: italic;
  color: #c30; }

.s2 {
  color: #c30; }

.se {
  color: #c30; }

.sh {
  color: #c30; }

.si {
  color: #a00; }

.sx {
  color: #c30; }

.sr {
  color: #3aa; }

.s1 {
  color: #c30; }

.ss {
  color: #fc3; }

.bp {
  color: #366; }

.vc {
  color: #033; }

.vg {
  color: #033; }

.vi {
  color: #033; }

.il {
  color: #f60; }

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999; }

.language-bash::before,
.language-sh::before {
  color: #009;
  content: "$ ";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.language-powershell::before {
  color: #009;
  content: "PM> ";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.anchorjs-link {
  font-weight: 400;
  color: rgba(0, 161, 255, 0.5);
  -webkit-transition: color .16s linear;
  transition: color .16s linear; }
  .anchorjs-link:hover {
    color: #00a1ff;
    text-decoration: none; }

/* Search input */
.algolia-autocomplete {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%; }
  .algolia-autocomplete .algolia-docsearch-suggestion,
  .algolia-autocomplete .ds-dropdown-menu::before {
    background-color: #000; }

/* Main dropdown wrapper */
.algolia-autocomplete .ds-dropdown-menu {
  margin-top: 1rem;
  background-color: #000;
  border: #333 solid 1px;
  border-radius: 0.4375rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  /* stylelint-disable selector-max-class, selector-max-compound-selectors */
  /* stylelint-enable selector-max-class, selector-max-compound-selectors */ }
  .algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
    background-color: #000;
    border: none;
    border-radius: 0.4375rem;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .algolia-autocomplete .ds-dropdown-menu .ds-suggestion.ds-cursor .algolia-docsearch-suggestion.suggestion-layout-simple,
  .algolia-autocomplete .ds-dropdown-menu .ds-suggestion.ds-cursor .algolia-docsearch-suggestion:not(.suggestion-layout-simple) .algolia-docsearch-suggestion--content {
    background-color: #333; }

@media (max-width: 767.98px) {
  .algolia-autocomplete .ds-dropdown-menu {
    min-width: 0; } }

/* Main category (eg. Getting Started) */
.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: rem(14px);
  font-size: 0.75rem;
  font-weight: 500;
  color: #d7d7d7;
  text-transform: uppercase;
  border: none; }

/* Category (eg. Downloads) */
.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  color: #d7d7d7; }

/* Title (eg. Bootstrap CDN) */
.algolia-autocomplete .algolia-docsearch-suggestion--title {
  font-size: 1rem;
  font-weight: 600;
  color: #f2f2f2; }

/* Description description (eg. Bootstrap currently works...) */
.algolia-autocomplete .algolia-docsearch-suggestion--text {
  font-size: .9rem;
  font-weight: 400;
  color: #f2f2f2; }

/* Highlighted text */
.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #00a1ff; }

/*# sourceMappingURL=src/scss/dark-docs-intern.scss.map */